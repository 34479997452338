import {query, orderByChild, equalTo, get } from 'firebase/database';
import {collectionItemArrayToCardDataArray, convertFirebaseArrayToJavascriptArray} from "~/helpers/helpers.js";
import {dbRef} from "~/helpers/dbRef.js";

export function createAsyncVersion(func) {
    return (...args) => {

        const stringArgs = args.map(arg => {
            if (arg === null) return 'simmerionull';
            if (arg === undefined) return 'simmerioundefined';
            if (typeof arg === 'number') return arg.toString();
            if (typeof arg !== 'string') {
                console.error(`[ERROR] Invalid argument type passed to ${func.name}:`, arg);
                throw new Error(`Argument ${arg} must be a string, null, or undefined`);
            }
            return arg;
        });

        const key = `useAsync-${func.name}-${stringArgs.join('-')}`;

        return useAsyncData(key, () => func(...args));
    };
}

export async function useDatabaseValue(path) {
    const database = useDatabase();
    const dbReference = dbRef(database, path);
    const snapshot = await get(dbReference);
    return snapshot.val();
}

export async function useQueryByChildren(dataPath, childName, childValue) {
    const database = useDatabase();
    const dbReference = dbRef(database, dataPath);
    const dbQuery = query(dbReference, orderByChild(childName), equalTo(childValue));

    const snapshot = await get(dbQuery);
    if (snapshot.exists()) {
        return snapshot.toJSON();
    } else {
        return {}; // Or handle the case where no data matches the query
    }
}

export async function useGetUserContent(uid = null, includePrivateGames = false) {

    if (!uid){
        const currentUser = useCurrentUser();
        if (!currentUser.value){
            throw new Error('CurrentUser not found');
        }
        uid = currentUser.value.uid;
        //
    }

    const username = await useDatabaseValue(`users/${uid}/username`);

    if (!uid) {
        throw new Error('UID not found');
    }

    const rawData = await useQueryByChildren('content', 'uid', uid);
    if (!rawData){
        throw new Error("Raw data not found.")
    }
    let asArray = Object.values(Object.entries(rawData).map(([guid, value]) => {
        return { ...value, guid, userData:{username} }; // stick the guid in the array item
    }));

    if (!includePrivateGames){
        asArray = asArray.filter(item => !!item.friendlyUrl);
    }

    asArray.sort((a, b) => b.timestamp - a.timestamp);

    return collectionItemArrayToCardDataArray(asArray);
}


export const useAsyncGetUserContent = createAsyncVersion(useGetUserContent);

export const useCurrentUserUid = async () => {
    const currentUser = useCurrentUser();
    if (!currentUser.value) {
        return null;
    }
    return currentUser.value.uid;
}

export async function useFriendlyUrlsFromCurrentUser() {
    const uid = await useCurrentUserUid();
    const rawData = await useQueryByChildren('content', 'uid', uid);
    return Object.values(rawData).map(item => item.friendlyUrl || null).filter(item => item !== null);
}

export async function useCurrentUserProfile() {
    const currentUser = useCurrentUser();
    if (!currentUser.value) {
        return null;
    }
    const uid = currentUser.value.uid;

    const [readOnlyUserData, userData] = await Promise.all([
        useDatabaseValue(`readOnlyUserData/${uid}`),
        useDatabaseValue(`users/${uid}`)
    ]);

    if (userData){
        userData.readOnlyUserData = readOnlyUserData;
    }
    return userData;
}

export const useAsyncCurrentUserProfile = createAsyncVersion(useCurrentUserProfile);

export async function useCurrentUserUsername() {
    const currentUser = await getCurrentUser();
    const uid = currentUser?.uid;
    if (!uid) {
        return null;
    }

    return await useDatabaseValue(`users/${uid}/username`);
}

export const useAsyncCurrentUserUsername = createAsyncVersion(useCurrentUserUsername);

export async function useCurrentUserReadOnlyData() {
    const currentUser = useCurrentUser();
    if (!currentUser.value) {
        throw new Error('CurrentUser not found');
    }
    const uid = currentUser.value.uid;

    return await useDatabaseValue(`readOnlyUserData/${uid}`);
}

function getContentGuid(object, value) {
    return Object.keys(object).find(key => object[key].uid === value) || null;
}

export const useAsyncCurrentUserReadOnlyData = createAsyncVersion(useCurrentUserReadOnlyData);


export async function useContentEntryByFriendlyUrl(username, friendlyUrl) {
    try {
        const uid = await useDatabaseValue(`usernames/${username.toLowerCase()}/uid`)
        if (!uid){
            console.log(`uid not found for user ${username}`);
            return null;
        }

        const contentEntries = await useQueryByChildren("content", 'friendlyUrl', friendlyUrl);

        if (!contentEntries){
            console.log(`no content for friendlyUrl ${friendlyUrl}`)
            return null;
        }

        const guid = getContentGuid(contentEntries, uid);
        const entry = contentEntries[guid];
        if (!entry){
            console.log(`User ${username} found, but has no content ${friendlyUrl}`)
            return null;
        }
        if(!entry.guid){
            entry.guid = guid;
        }

        return entry;
    } catch (error) {
        console.error('Unknown error fetching content entry:', error);
        return null;
    }
}

export const useAsyncContentEntryByFriendlyUrl = createAsyncVersion(useContentEntryByFriendlyUrl);

export async function useContentEntryByGuid(username, guid){
    const contentEntry = await useDatabaseValue(`content/${guid}`);
    if (!contentEntry){
        console.log(`no content found at guid ${guid}`)
        return null;
    }

    const uid = await useDatabaseValue(`usernames/${username.toLowerCase()}/uid`)
    if (!uid){
        console.log(`uid not found for user ${username}`);
        return null;
    }

    if (uid !== contentEntry.uid){
        console.log(`uid ${uid} found and contentEntry at ${guid} found, but the content does not match the user.`)
        return null;
    }

    if (!contentEntry.guid) {
        contentEntry.guid = guid;
    }

    return contentEntry;
}

export const useAsyncContentEntryByGuid = createAsyncVersion(useContentEntryByGuid);

// pageIdx is 0 based
export async function useCollectionByFriendlyUrl(friendlyUrl, pageIdx = 0, gamesPerPage = 30) {
    console.log(`[INFO] Fetching collection for friendlyUrl: "${friendlyUrl}", Page: ${pageIdx + 1}`);

    const startTime = performance.now(); // Start timing execution

    try {
        const collectionQueryResult = await useQueryByChildren("collections", "friendlyUrl", friendlyUrl);

        if (!collectionQueryResult) {
            console.warn(`[WARN] No collections found with friendlyUrl: "${friendlyUrl}"`);
            return null;
        }

        const collections = Object.entries(collectionQueryResult).map(([guid, value]) => ({ ...value, guid }));

        if (collections.length !== 1) {
            console.error(`[ERROR] Expected 1 collection for "${friendlyUrl}", found ${collections.length}`);
            return null;
        }

        const [collection] = collections;
        const gameGuidsAll = convertFirebaseArrayToJavascriptArray(collection.items ?? []).map(item => item.guid);

        console.log(`[INFO] Collection "${friendlyUrl}" contains ${gameGuidsAll.length} games.`);

        const BUFFER = 5;
        const startIdx = pageIdx * gamesPerPage;
        const endIdx = startIdx + (gamesPerPage + BUFFER);
        const gameGuids = gameGuidsAll.slice(startIdx, endIdx);

        console.log(`[INFO] Fetching games ${startIdx}-${endIdx} out of ${gameGuidsAll.length}.`);

        const gameData = (
            await Promise.all(
                gameGuids.map(async (guid) => {
                    try {
                        const val = await useDatabaseValue(`content/${guid}`);
                        if (val && !val.guid) {
                            console.warn(`[WARN] Game ${guid} missing GUID, adding manually.`);
                            val.guid = guid;
                        }
                        return val;
                    } catch (error) {
                        console.error(`[ERROR] Failed to fetch game ${guid}:`, error);
                        return null;
                    }
                })
            )
        ).filter(Boolean); // Remove null/undefined entries (i.e., deleted games)

        const unavailableGamesCount = gameGuids.length - gameData.length;

        console.log(`[INFO] Retrieved ${gameData.length}/${gameGuids.length} games successfully.`);

        const uniqueUids = [...new Set(gameData.map(game => game.uid))];

        console.log(`[INFO] Fetching usernames for ${uniqueUids.length} unique users.`);

        const usernames = await Promise.all(
            uniqueUids.map(async (uid) => {
                try {
                    return await useDatabaseValue(`users/${uid}/username`);
                } catch (error) {
                    console.error(`[ERROR] Failed to fetch username for user ${uid}:`, error);
                    return null;
                }
            })
        );

        const uidToUsername = Object.fromEntries(uniqueUids.map((uid, idx) => [uid, usernames[idx]]));

        const enrichedGameData = gameData
            .map(game => {
                const username = uidToUsername[game.uid];
                if (!username) {
                    console.warn(`[WARN] Removing game ${game.guid} due to missing username.`);
                    return null;
                }
                return { ...game, userData: { username } };
            })
            .filter(Boolean); // Remove games with missing users

        const gamesWithoutUsersCount = gameData.length - enrichedGameData.length;

        if (unavailableGamesCount > 0 || gamesWithoutUsersCount > 0) {
            console.warn(
                `[WARN] Collection "${friendlyUrl}" (Page ${pageIdx + 1}) has ` +
                `${unavailableGamesCount} unavailable games and ` +
                `${gamesWithoutUsersCount} games without users. Pagination may not be accurate.`
            );
        }

        collection.items = collectionItemArrayToCardDataArray(enrichedGameData.slice(0, gamesPerPage));
        collection.totalPages = Math.ceil(gameGuidsAll.length / gamesPerPage);

        const executionTime = performance.now() - startTime; // Measure execution time
        console.log(`[INFO] Completed fetching collection "${friendlyUrl}" in ${executionTime.toFixed(2)}ms.`);

        return collection;
    } catch (error) {
        console.error(`[ERROR] Critical failure in useCollectionByFriendlyUrl(${friendlyUrl}):`, error);
        return null;
    }
}

export const useAsyncCollectionByFriendlyUrl = createAsyncVersion(useCollectionByFriendlyUrl);

export async function useCollectionExists(friendlyUrl) {
    const collectionQueryResult = await useQueryByChildren("collections", 'friendlyUrl', friendlyUrl);
    return collectionQueryResult !== null && Object.keys(collectionQueryResult).length > 0;
}

export const useAsyncCollectionExists = createAsyncVersion(useCollectionExists);
export async function useCollectionsByUid(uid, includeCollectionItems = true) {
    const rawCollection = await useQueryByChildren("collections", 'uid', uid);
    if (!rawCollection) {
        console.log(`No collections found for user ${uid}.`);
        return [];
    }

    const rawCollectionKeys = Object.keys(rawCollection);
    rawCollectionKeys.forEach(guid => {
        rawCollection[guid].guid = guid;
        rawCollection[guid].items = rawCollection[guid].items ?? {};
        if (includeCollectionItems){
            rawCollection[guid].items = Object.values(rawCollection[guid].items).map(item => item.guid);
        }
        else{
            delete rawCollection[guid].items;
        }
    });


    const collectionsAsArray = Object.values(rawCollection);
    collectionsAsArray.sort((a, b) => b.timestamp - a.timestamp);
    return collectionsAsArray;
}

export async function useCustomLoadingScreens(){
    const uid = await useCurrentUserUid();
    const loaders = await useQueryByChildren("loading", "uid", uid);
    return Object.values(loaders).map((loader, index) => ({
        ...loader,
        id: Object.keys(loaders)[index]
    }));
}